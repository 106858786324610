@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  width: 100%;
  height: 100vh;
}

.target-zc {
  @apply animate-pulse;
}